import React, { useState } from 'react';

const UniversityInquiryForm = () => {
    const [formData, setFormData] = useState({
        universityName: '',
        department: '',
        universityWebsite: '',
        accreditationNumber: '',
        fullName: '',
        designation: '',
        email: '',
        phoneNumber: '',
        inquiryType: '',
        subject: '',
        description: '',
        expectedTimeline: '',
        preferredCommunication: 'Email'
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const response = await fetch('/api/university/inquiries', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                alert('Inquiry submitted successfully!');
                setFormData({
                    universityName: '',
                    department: '',
                    universityWebsite: '',
                    accreditationNumber: '',
                    fullName: '',
                    designation: '',
                    email: '',
                    phoneNumber: '',
                    inquiryType: '',
                    subject: '',
                    description: '',
                    expectedTimeline: '',
                    preferredCommunication: 'Email'
                });
            } else {
                alert('Error submitting inquiry');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error submitting inquiry');
        }
    };

    return (
        <div
            className="min-h-screen flex items-center justify-center bg-cover bg-center p-6"
            style={{ backgroundImage: "url('https://iimstc.com/wp-content/uploads/slider/cache/e0a4105f14d43d1eae165e5b1f24238b/slider23.jpg')" }}
        >
            <form className="p-6 bg-black bg-opacity-70 shadow-md rounded-md text-white w-full max-w-2xl" onSubmit={handleSubmit}>
                <h2 className="text-lg font-bold mb-4 col-span-2">1. University/Department Details</h2>
                <div className="grid grid-cols-2 gap-4">
                    {/* Form Fields */}
                    <div className="col-span-1">
                        <label>University Name *</label>
                        <input
                            type="text"
                            name="universityName"
                            value={formData.universityName}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        />
                    </div>
                    <div className="col-span-1">
                        <label>Department</label>
                        <select
                            name="department"
                            value={formData.department}
                            onChange={handleChange}
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        >
                            <option value="">Select Department</option>
                            <option value="Engineering">Engineering</option>
                            <option value="Business">Business</option>
                            <option value="Healthcare">Healthcare</option>
                            <option value="Arts">Arts</option>
                        </select>
                    </div>
                    <div className="col-span-1">
                        <label>University Website</label>
                        <input
                            type="url"
                            name="universityWebsite"
                            value={formData.universityWebsite}
                            onChange={handleChange}
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        />
                    </div>
                    <div className="col-span-1">
                        <label>Accreditation Number</label>
                        <input
                            type="text"
                            name="accreditationNumber"
                            value={formData.accreditationNumber}
                            onChange={handleChange}
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        />
                    </div>

                    <h2 className="text-lg font-bold mt-4 mb-2 col-span-2">2. Contact Information</h2>

                    <div className="col-span-1">
                        <label>Full Name *</label>
                        <input
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        />
                    </div>

                    <div className="col-span-1">
                        <label>Designation/Role</label>
                        <input
                            type="text"
                            name="designation"
                            value={formData.designation}
                            onChange={handleChange}
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        />
                    </div>

                    <div className="col-span-1">
                        <label>Email Address *</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        />
                    </div>

                    <div className="col-span-1">
                        <label>Phone Number *</label>
                        <input
                            type="number"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        />
                    </div>

                    <h2 className="text-lg font-bold mt-4 mb-2 col-span-2">3. Inquiry Details</h2>

                    <div className="col-span-1">
                        <label>Subject of Inquiry *</label>
                        <input
                            type="text"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        />
                    </div>

                    <div className="col-span-1">
                        <label>Inquiry Type</label>
                        <select
                            name="inquiryType"
                            value={formData.inquiryType}
                            onChange={handleChange}
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        >
                            <option value="">Select Inquiry Type</option>
                            <option value="EdgeProgram">To know about Edge Program</option>
                            <option value="Collaboration">Collaboration</option>
                            <option value="Others">Others</option>
                          
                        </select>
                    </div>

                    <div className="col-span-2">
                        <label>Detailed Description *</label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        ></textarea>
                    </div>
                </div>

                {/* Buttons */}
                <div className="flex justify-between mt-6">
                    <button type="button" className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Cancel
                    </button>
                    <button type="submit" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UniversityInquiryForm;
