import React, { useState } from 'react';

const IndustryInquiryForm = () => {
    const [formData, setFormData] = useState({
        companyName: '',
        industryType: '',
        companyWebsite: '',
        businessRegistrationNumber: '',
        fullName: '',
        designation: '',
        email: '',
        phoneNumber: '',
        subject: '',
        inquiryType: '',
        description: '',
        expectedTimeline: '',
        preferredCommunication: 'Email'
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const response = await fetch('/api/industry-inquiry/inquiries', {  // Backend API URL
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Failed to submit the inquiry');
            }

            const result = await response.json();
            alert(result.message); // Success message from backend
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('There was an error submitting the form.');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-cover bg-center p-6" 
            style={{ backgroundImage: "url('https://iimstc.com/wp-content/uploads/slider/cache/e0a4105f14d43d1eae165e5b1f24238b/slider23.jpg')" }}>
            <form onSubmit={handleSubmit} className="p-6 bg-black bg-opacity-70 shadow-md rounded-md text-white w-full max-w-2xl">
                <h2 className="text-lg font-bold mb-4 col-span-2">1. Company/Organization Details</h2>

                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-1">
                        <label>Company Name *</label>
                        <input
                            type="text"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        />
                    </div>

                    <div className="col-span-1">
                        <label>Industry Type</label>
                        <select
                            name="industryType"
                            value={formData.industryType}
                            onChange={handleChange}
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        >
                            <option value="">Select Industry</option>
                            <option value="Manufacturing">Manufacturing</option>
                            <option value="IT">IT</option>
                            <option value="Logistics">Logistics</option>
                            <option value="Healthcare">Healthcare</option>
                        </select>
                    </div>

                    <div className="col-span-1">
                        <label>Company Website</label>
                        <input
                            type="url"
                            name="companyWebsite"
                            value={formData.companyWebsite}
                            onChange={handleChange}
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        />
                    </div>

                    <div className="col-span-1">
                        <label>Business Registration Number</label>
                        <input
                            type="text"
                            name="businessRegistrationNumber"
                            value={formData.businessRegistrationNumber}
                            onChange={handleChange}
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        />
                    </div>

                    <h2 className="text-lg font-bold mt-4 mb-2 col-span-2">2. Contact Information</h2>

                    <div className="col-span-1">
                        <label>Full Name *</label>
                        <input
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        />
                    </div>

                    <div className="col-span-1">
                        <label>Designation/Role</label>
                        <input
                            type="text"
                            name="designation"
                            value={formData.designation}
                            onChange={handleChange}
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        />
                    </div>

                    <div className="col-span-1">
                        <label>Email Address *</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        />
                    </div>

                    <div className="col-span-1">
                        <label>Phone Number *</label>
                        <input
                            type="number"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        />
                    </div>

                    <h2 className="text-lg font-bold mt-4 mb-2 col-span-2">3. Inquiry Details</h2>

                    <div className="col-span-2">
                        <label>Subject of Inquiry *</label>
                        <input
                            type="text"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        />
                    </div>

                  
                    <div className="col-span-2">
                        <label>Detailed Description *</label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border rounded bg-gray-300 text-black"
                        ></textarea>
                    </div>
                </div>

                {/* Buttons */}
                <div className="flex justify-between mt-6">
                    <button type="button" className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Cancel
                    </button>
                    <button type="submit" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default IndustryInquiryForm;
