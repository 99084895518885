import React, { useState, ChangeEvent, FormEvent } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import '../styles/logincss.css'; // Your CSS file

const Log: React.FC = () => {
  const [role, setRole] = useState<'admin' | 'user'>('admin'); // Toggle between roles
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  // Handle input changes
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  // Handle login form submission
  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);

    // Define API URL & Payload
    const endpoint = role === 'admin'
      ? '/api/auth/login'
      : '/api/users/login';

    const payload = role === 'admin'
      ? { username: credentials.username, password: credentials.password }
      : { IIMSTC_ID: credentials.username, password: credentials.password }; // Use correct field for User

    try {
      const response = await axios.post(endpoint, payload);
      const { token, user, adminId } = response.data;

      // Store different data based on role
      localStorage.setItem('token', token);
      if (role === 'admin') {
        localStorage.setItem('adminId', adminId);
        navigate('/dashboard'); // Redirect Admin to Dashboard
      } else {
        localStorage.setItem('IIMSTC_ID', user.IIMSTC_ID);
        localStorage.setItem('degreeStatusId', user.degreeStatusId);
        navigate('/student'); // Redirect User to User Dashboard
      }
    } catch (err: any) {
      console.error('Login Error:', err.response?.data || err.message);
      setError(err.response?.data?.message || 'Invalid username or password');
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <fieldset className="login-form-section">
          <legend className="login-form-legend">
            <span className="login-text"><strong>LOGIN</strong></span>
          </legend>

          {/* Role Selection Buttons */}
          <div className="p-4 flex flex-row items-center gap-2 justify-center">
            <button
              type="button"
              className={`w-1/2 py-2 rounded-md ${role === 'admin' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-black'}`}
              onClick={() => setRole('admin')}
            >
              Management
            </button>
            <button
              type="button"
              className={`w-1/2 py-2 rounded-md ${role === 'user' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-black'}`}
              onClick={() => setRole('user')}
            >
              Student
            </button>
          </div>

          {/* Input Fields */}
          <div className="login-form-group">
            <label htmlFor="username" className="login-form-label">{role === 'admin' ? 'Username' : 'IIMSTC ID'}</label>
            <input
              id="username"
              name="username"
              type="text"
              placeholder={role === 'admin' ? 'Enter Username' : 'Enter IIMSTC ID'}
              className="login-form-input"
              value={credentials.username}
              onChange={handleChange}
              required
            />
          </div>

          <div className="login-form-group">
            <label htmlFor="password" className="login-form-label">Password</label>
            <div className="password-container">
              <input
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter Password"
                className="login-form-input"
                value={credentials.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="show-password-btn"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>
        </fieldset>

        {/* Buttons */}
        <div className="login-form-btn-container">
          <button type="submit" className="login-form-btn login-form-btn-submit">Login</button>
          <button type="button" className="login-form-btn login-form-btn-go-back" onClick={() => navigate(-1)}>Go Back</button>
        </div>

        {/* Error Message */}
        {error && <p className="login-error">{error}</p>}

        {/* Registration Link */}
        <div className="login-additional-links">
          <p>Don't have an account? <Link to="/register" className="login-link">Register Now</Link></p>
        </div>
      </form>
    </div>
  );
};

export default Log;
