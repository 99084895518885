import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { Menu, X } from "lucide-react"; // Import icons


const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isReversing, setIsReversing] = useState(false);

 const slides = [
    {
      heading: "For Students: Unlock Your Future with Skill Development and Industry Connections",
      paragraph:
        "Gain hands-on experience, develop essential skills, and connect with top companies to kickstart your career through our tailored internship programs.",
      imgSrc: "./image/Onlinereading.gif",
      imgAlt: "Slide 1 Image",
      sectionId: "student",
    },
    {
      heading: "For Industry: Access Skilled Interns Ready to Contribute",
      paragraph:
        "Find highly skilled and motivated interns who are eager to make a real impact in your organization, enhancing your team while shaping future talent.",
      imgSrc: "./image/Designer.gif",
      imgAlt: "Slide 2 Image",
      sectionId: "industry",
    },
    {
      heading: "For Universities: Seamless Internship Tracking and Support for Your Students",
      paragraph:
        "Monitor your students’ internship progress with our platform, ensuring they receive the guidance and support they need to succeed in the professional world.",
      imgSrc: "./image/OnlineLearning.gif",
      imgAlt: "Slide 3 Image",
      sectionId: "university",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        if (!isReversing) {
          if (prevIndex < slides.length - 1) {
            return prevIndex + 1; // Move forward
          } else {
            setIsReversing(true);
            return prevIndex - 1; // Start moving backward
          }
        } else {
          if (prevIndex > 0) {
            return prevIndex - 1; // Move backward
          } else {
            setIsReversing(false);
            return prevIndex + 1; // Start moving forward again
          }
        }
      });
    }, 4500);

    return () => clearInterval(interval);
  }, [isReversing]);



  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    const handleChange = (e: MediaQueryListEvent) => {
      setIsMobile(e.matches);
    };

    // Initial check
    setIsMobile(mediaQuery.matches);

    // Add event listener
    mediaQuery.addEventListener('change', handleChange);

    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  // Background images for different sections
  const sectionBackgrounds = {
    home: isMobile ? '/image/backgroundPageOneRes.png' : '/image/backgroundPageOne.png',
    student: isMobile ? '/image/backgroundPageTwoRes.png' : '/image/backgroundPageTwo.png',
    industry: isMobile ? '/image/backgroundPageFiveRes.png' : '/image/backgroundPageFive.png',
   
  };


  const [isOpen, setIsOpen] = useState(false);


  return (
    <div className=" text-black min-h-screen">

      {/* Navigation Bar */}
      <nav className="fixed top-0 left-0 right-0 z-10 flex justify-between items-center p-4 bg-gray-900 shadow-md">
        {/* Logo */}
        <div className="ml-4 sm:ml-10">
          <img src="./image/log.webp" alt="logo" className="w-24" />
        </div>

        {/* Desktop Navigation */}
        <div className="hidden md:flex items-center gap-12 pr-14 text-white">
          <a href="#home" className="hover:text-yellow-400">Home</a>
          <a href="#student" className="hover:text-yellow-400">Student</a>
          <a href="#industry" className="hover:text-yellow-400">Industry</a>
          <a href="#university" className="hover:text-yellow-400">University</a>
          <a href="#about" className="hover:text-yellow-400">About Us</a>
          <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={() => navigate("/login")}>
            Login
          </button>
        </div>

        {/* Mobile Menu Toggle */}
        <button
          className="md:hidden p-2"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <X size={28} /> : <Menu size={28} />}
        </button>

        {/* Mobile Dropdown Menu */}
        {isOpen && (
          <div className="absolute top-16 left-0 right-0 bg-gray-900 shadow-lg flex flex-col items-center gap-4 py-4 md:hidden text-white">
            <a href="#home" className="hover:text-yellow-400" onClick={() => setIsOpen(false)}>Home</a>
            <a href="#student" className="hover:text-yellow-400" onClick={() => setIsOpen(false)}>Student</a>
            <a href="#industry" className="hover:text-yellow-400" onClick={() => setIsOpen(false)}>Industry</a>
            <a href="#university" className="hover:text-yellow-400" onClick={() => setIsOpen(false)}>University</a>
            <a href="#about" className="hover:text-yellow-400" onClick={() => setIsOpen(false)}>About Us</a>
            <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={() => { setIsOpen(false); navigate("/login"); }}>
              Login
            </button>
          </div>
        )}
      </nav>


      {/* Home Page */}
      <section id="home" className="w-full h-screen flex items-center justify-center pt-12">
        <div
          className="w-full h-full flex flex-col md:flex-row relative bg-cover bg-center"
          style={{
            backgroundImage: `url(${sectionBackgrounds.home})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="w-full md:w-2/3 h-full p-6 flex flex-col justify-center">
            <div className="mx-6 md:mx-10 text-center md:text-left">
              <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-black">
                The Edge - Diamond beneath your feet Program
              </h1>
              <p className="mt-3 text-base sm:text-lg text-black">
                Empowering students with internships, industry connections, and essential skills for a successful career.
              </p>
              <div className="mt-4">
                <button
                  className="px-5 py-2 bg-blue-500 text-white rounded-lg text-sm sm:text-base"
                  onClick={() => document.getElementById("aboutProgram")?.scrollIntoView({ behavior: "smooth" })}
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/3 flex items-end justify-center md:justify-start">
            <img
              src="/image/PageOneStudent.png"
              alt="Image Description"
              className="w-[80%] md:w-full h-auto object-cover object-bottom"
            />
          </div>
        </div>
      </section>


      {/* Carousel Section */}
      <section id="aboutProgram" className="w-full  h-screen flex flex-col items-center text-white bg-gray-900 py-12">
        <div className="relative overflow-hidden w-full mx-6">
          <div
            className="flex transition-transform duration-700 ease-in-out"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {slides.map((slide, index) => (
              <div key={index} className="min-w-full flex flex-col md:flex-row items-center gap-6 p-6">
                <div className="md:w-1/2 text-center pl-0 md:pl-20">
                  <h1 className="text-2xl font-bold text-yellow-400">{slide.heading}</h1>
                  <p className="text-lg my-4">{slide.paragraph}</p>
                   <button className="bg-purple-600 px-6 py-2 rounded" onClick={() => document.getElementById(slide.sectionId)?.scrollIntoView({ behavior: "smooth" })}
                  >
                    Learn More
                  </button>
                </div>
                <div className="md:w-1/2 flex justify-center">
                  <img src={slide.imgSrc} alt={slide.imgAlt} className="max-w-full h-auto rounded-lg" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>



      {/* Student Info Section */}
      <section id="student" className="w-full h-[70vh] flex items-center justify-center">

        <div
          className="w-full h-full flex flex-col md:flex-row relative bg-cover bg-center p-10 pb-0"
          style={{
            backgroundImage: `url(${sectionBackgrounds.student})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
        >
          <div className="w-full md:w-2/3 h-full p-0 md:p-6 flex justify-center">
            <div className="mx-0 md:mx-10 text-center relative">

              <h1 className="text-2xl text-left sm:text-3xl md:text-4xl pt-10 font-bold text-black">
                How can you future-proof your career in an evolving job market?
              </h1>
              <p className="mt-3 text-lg text-left sm:text-xl text-black">
                Gain hands-on experience, develop in-demand skills, and connect with industry leaders through structured learning programs. Stay competitive by working on real-world projects, mastering emerging technologies, and expanding your network in an AI-driven world.
              </p>
              <button className="absolute mt-4 left-0  hover:bg-blue-700 transition px-5 py-2 bg-blue-500 text-white rounded-lg text-sm sm:text-base" onClick={() => navigate("/student/applicationform")} >
                Know More
              </button>
            </div>
          </div>



          <div className="w-full md:w-[30%] flex items-end justify-center md:justify-start relative md:static">
            <img
              src="/image/PageTwoStudent.png"
              alt="Image Description"
              className="w-[60%] md:w-[80%] h-auto object-cover object-bottom absolute right-0 bottom-0 md:relative"
            />
          </div>
        </div>
      </section>
      <section className="w-full min-h-[70vh] flex items-center  justify-center px-0 ">
        {/* Background Container */}
        <div
          className="w-full h-full min-h-[70vh] flex flex-col items-center justify-center relative bg-cover bg-center"
          style={{
            backgroundImage: 'url(/image/backgroundPageThree.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          {/* Heading & Paragraph - Full Width */}
          <div className="w-full text-center px-4 md:px-20">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-black">
              International Internship Program
            </h1>

            {/* Paragraph */}
            <p className="text-black mt-4 text-sm sm:text-base md:text-lg max-w-4xl mx-auto">
              Gain real-world experience through structured training, industry mentorship, and hands-on projects.
              Enhance your skills, expand your global network, and boost employability. Outstanding students
              receive up to 80% scholarship, with top performers eligible for a full scholarship.
            </p>
          </div>

          {/* Cards - Positioned Below */}
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 justify-center pb-12 items-center mt-16 px-4 md:px-16">
            {/* Card 1 */}
            <div className="bg-white p-6 rounded-3xl shadow-[0px_10px_30px_rgba(0,0,0,0.25)] w-[95%] sm:w-80 h-auto flex flex-col items-center justify-center text-center mx-auto">
              <h2 className="text-lg font-bold text-teal-600 pb-3 sm:pb-4">Skill Development</h2>
              <p className="text-gray-700 text-sm sm:text-base">
                Gain industry-specific training, hands-on projects, and enhanced research and soft skills.
              </p>
            </div>

            {/* Card 2 */}
            <div className="bg-white p-6 rounded-3xl shadow-[0px_10px_30px_rgba(0,0,0,0.25)] w-[95%] sm:w-80 h-auto flex flex-col items-center justify-center text-center mx-auto">
              <h2 className="text-lg font-bold text-teal-600 pb-3 sm:pb-4">Global Exposure</h2>
              <p className="text-gray-700 text-sm sm:text-base">
                Internships with top organizations, AI-powered career guidance, and globally recognized certifications.
              </p>
            </div>

            {/* Card 3 */}
            <div className="bg-white p-6 rounded-3xl shadow-[0px_10px_30px_rgba(0,0,0,0.25)] w-[95%] sm:w-80 h-auto flex flex-col items-center justify-center text-center mx-auto">
              <h2 className="text-lg font-bold text-teal-600 pb-3 sm:pb-4">Career Opportunities</h2>
              <p className="text-gray-700 text-sm sm:text-base">
                Pathway from internships to jobs, with placement assistance and exclusive networking opportunities.
              </p>
            </div>
          </div>

        </div>
      </section>







      {/* Industry Info Section */}
      <section id="industry" className="w-full h-[80vh] flex items-center justify-center mt-3 md:mt-12">
        <div
          className="w-full h-full flex flex-col md:flex-row relative bg-cover bg-center p-6 md:p-10 pb-0"
          style={{
            backgroundImage: `url(${sectionBackgrounds.industry})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
        >
          {/* Text Content */}
          <div className="w-full md:w-2/3 h-full p-0 md:p-6 flex justify-center">
            <div className="mx-0 md:mx-10 text-center md:text-left relative">
              <h1 className="text-2xl sm:text-3xl md:text-4xl pt-10 font-bold text-black">
                Struggling to Find Skilled Talent? We’ve Got You Covered.
              </h1>
              <p className="mt-3 text-lg sm:text-xl text-black">
                With industries facing a widening skills gap, securing diverse, job-ready talent has never been more critical.
                Our platform streamlines the process, helping you onboard interns swiftly and access additional support—all
                without the burden of hiring costs or recruitment overheads.
              </p>
              <button className="mt-4 hover:bg-blue-700 transition px-5 py-2 bg-blue-500 text-white rounded-lg text-sm sm:text-base" onClick={() => navigate("/Industry-Inquiry")}>
                Know More
              </button>
            </div>
          </div>

          {/* Image Container */}
          <div className="w-full md:w-[40%] flex items-end justify-end md:justify-start mt-6 md:mt-0">
            <img
              src="/image/CompanyPageImage.png"
              alt="Image Description"
              className="w-[60%] md:w-[80%] h-auto object-cover object-bottom"
            />
          </div>
        </div>
      </section>




      <section className="w-full min-h-[70vh] bg-gray-900 flex items-center justify-center px-0 md:px-10 py-10 relative">
        {/* Full-Size Background Image */}
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: 'url(/image/backgroundPageSix.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div>

        {/* Content Container */}
        <div className="w-full h-full flex flex-col items-center justify-center relative z-10 px-4 md:px-20">
          {/* Heading & Paragraph */}
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-white text-center">
            Boost Productivity with Top-Talent Interns
          </h1>

          <p className="text-white mt-4 text-sm sm:text-base md:text-lg max-w-4xl text-center">
            Leverage a pool of eager, pre-qualified interns to take on critical tasks, optimize operations, and fuel
            innovation—all with zero financial commitment. Empower your workforce with fresh perspectives and valuable skills.
          </p>

          {/* Cards Below */}
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 justify-center pb-12 items-center px-3 md:px-0 mt-16">
            {/* Card 1 */}
            <div className="bg-white p-6 rounded-3xl shadow-[0px_10px_30px_rgba(0,0,0,0.25)] sm:w-80 h-auto flex flex-col items-center justify-center text-center">
              <h2 className="text-lg font-bold text-teal-600 pb-3">Work from Anywhere, Anytime</h2>
              <p className="text-gray-700 text-sm sm:text-base">
                Easily onboard and collaborate with interns remotely, ensuring seamless workflow from any location.
              </p>
            </div>

            {/* Card 2 */}
            <div className="bg-white p-6 rounded-3xl shadow-[0px_10px_30px_rgba(0,0,0,0.25)] sm:w-80 h-auto flex flex-col items-center justify-center text-center">
              <h2 className="text-lg font-bold text-teal-600 pb-3">Cost-Free Talent Acquisition</h2>
              <p className="text-gray-700 text-sm sm:text-base">
                Get fully funded interns from top institutions—no recruitment fees or hidden expenses.
              </p>
            </div>

            {/* Card 3 */}
            <div className="bg-white p-6 rounded-3xl shadow-[0px_10px_30px_rgba(0,0,0,0.25)] sm:w-80 h-auto flex flex-col items-center justify-center text-center">
              <h2 className="text-lg font-bold text-teal-600 pb-3">Rapid Talent Deployment</h2>
              <p className="text-gray-700 text-sm sm:text-base">
                Need extra hands on deck? Onboard skilled interns within days to clear backlogs and accelerate projects.
              </p>
            </div>
          </div>
        </div>
      </section>




  







      {/*University Page*/}
      <section
        id="university"
        className="w-full h-auto md:h-[85vh] flex items-center justify-center mt-12"
      >
        <div
          className="w-full h-full flex flex-col md:flex-row relative bg-cover bg-center px-4 pt-4 pb-0 md:p-10 md:pb-0"
          style={{
            backgroundImage: 'url(/image/backgroundPageSeven.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
        >
          {/* Content Section */}
          <div className="w-full md:w-2/3 h-full p-4 md:p-6 flex justify-center">
            <div className="mx-4 sm:mx-6 md:mx-10 text-center md:text-left relative">
              <h1 className="text-2xl sm:text-3xl md:text-4xl pt-6 sm:pt-10 mb-4 sm:mb-6 font-bold text-black">
                Turning Education into Real-World Success
              </h1>
              <p className="mt-2 sm:mt-3 text-base sm:text-lg md:text-xl text-black">
                Give all your students guaranteed industry exposure, ensuring they gain the skills, experience, and confidence employers demand. Bridge the gap between learning and employment with real-world projects, expert mentorship, and practical training.
                Empower students with hands-on experience, making them job-ready from day one. Transform education into a launchpad for career success.
              </p>
              <div className="flex justify-center md:justify-start">
                <button className="mt-4 hover:bg-blue-700 transition px-5 py-2 bg-blue-500 text-white rounded-lg text-sm sm:text-base" onClick={() => navigate("/University-Inquiry")}>
                  Know More
                </button>
              </div>
            </div>
          </div>

          {/* Image Section - Right-bottom corner on desktop, bottom baseline & right-aligned on mobile */}
          <div className="relative w-full md:w-[40%] h-auto md:h-full flex justify-end md:justify-end items-end md:absolute md:bottom-0 md:right-0">
            <img
              src="/image/Arinya.png"
              alt="Image Description"
              className="w-[60%] sm:w-[50%] md:w-full h-auto object-cover"
            />
          </div>
        </div>
      </section>





      <section className="w-full min-h-[70vh] flex items-center mt-12 justify-center px-0 ">
        {/* Background Container */}
        <div
          className="w-full h-full min-h-[70vh] flex flex-col items-center justify-center relative bg-cover bg-center"
          style={{
            backgroundImage: 'url(/image/backgroundPageThree.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          {/* Heading & Paragraph - Full Width */}
          <div className="w-full text-center px-4 md:px-20">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-black">
              Real-World Skills for Career Success
            </h1>

            {/* Paragraph */}
            <p className="text-black mt-4 text-sm sm:text-base md:text-lg max-w-4xl mx-auto">
              Enhance your students' employability with a tailored platform that equips them with in-demand skills through industry-led projects, preparing them for a competitive job market.
            </p>
          </div>

          {/* Cards - Positioned Below */}
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 justify-center pb-12 items-center mt-16 px-4 md:px-16">
            {/* Card 1 */}
            <div className="bg-white p-6 rounded-3xl shadow-[0px_10px_30px_rgba(0,0,0,0.25)] w-[95%] sm:w-80 h-auto flex flex-col items-center justify-center text-center mx-auto">
              <h2 className="text-lg font-bold text-teal-600 pb-3 sm:pb-4">Effortless Career Support</h2>
              <p className="text-gray-700 text-sm sm:text-base">
                Eliminate time-consuming job searches and streamline the path to meaningful professional development.
              </p>
            </div>

            {/* Card 2 */}
            <div className="bg-white p-6 rounded-3xl shadow-[0px_10px_30px_rgba(0,0,0,0.25)] w-[95%] sm:w-80 h-auto flex flex-col items-center justify-center text-center mx-auto">
              <h2 className="text-lg font-bold text-teal-600 pb-3 sm:pb-4">Guaranteed Internship Opportunities</h2>
              <p className="text-gray-700 text-sm sm:text-base">
                Every student secures a project-based learning experience tailored to their chosen field in just a few weeks.
              </p>
            </div>

            {/* Card 3 */}
            <div className="bg-white p-6 rounded-3xl shadow-[0px_10px_30px_rgba(0,0,0,0.25)] w-[95%] sm:w-80 h-auto flex flex-col items-center justify-center text-center mx-auto">
              <h2 className="text-lg font-bold text-teal-600 pb-3 sm:pb-4">Scalable Global Partnerships</h2>
              <p className="text-gray-700 text-sm sm:text-base">
                From small cohorts to large-scale university collaborations, our platform supports institutions of any size.
              </p>
            </div>
          </div>

        </div>
      </section>


    



      <section className="w-full pt-10 flex bg-gray-900 items-center justify-center">
        <div className="container mx-auto px-6 py-12 text-white">
          <div className="flex flex-col md:flex-row justify-between">
            <div className="mb-8 md:mb-0">
              <h2 className="text-xl font-semibold">Contact Us</h2>
              <div className="flex flex-col gap-2 mt-2 opacity-75">
                {/* Phone Numbers */}
                <div className="flex flex-wrap gap-4">
                  <span className="text-xl">Phone Number:</span>
                  <a href="tel:+918553440530" className="text-blue-500 hover:underline">
                    +918553440530
                  </a>
                  <span>/</span>
                  <a href="tel:+918197943343" className="text-blue-500 hover:underline">
                    +918197943343
                  </a>
                </div>

                {/* Email Addresses */}
                <div className="flex flex-wrap gap-4">
                  <span className="text-xl">Email:</span>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=directoracademic@iimstc.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    directoracademic@iimstc.com
                  </a>
                  <span>/</span>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=principal@iimstc.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    principal@iimstc.com
                  </a>
                </div>
              </div>



            </div>

            <div className="flex flex-col md:flex-row gap-10">
              <div>
                <h3 className="text-lg font-medium text-white">Quick Links</h3>
                <ul className="mt-4 space-y-2">
                  <li><a href="#student" className="hover:underline">Student</a></li>
                  <li><a href="#industry" className="hover:underline">Industry</a></li>
                  <li><a href="#university" className="hover:underline">University</a></li>
                </ul>
              </div>

              <div>
                <h3 className="text-lg font-medium text-white">Follow Us</h3>
                <ul className="mt-4 space-y-2">
                  <li>
                    <a href="https://www.facebook.com/iimstc/" className="hover:underline flex items-center">
                      <FaFacebook size={20} className="mr-2 text-[#1877F2]" /> Facebook
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/iimstc/" className="hover:underline flex items-center">
                      <FaInstagram size={20} className="mr-2 text-[#E4405F]" /> Instagram
                    </a>
                  </li>

                  <li>
                    <a href="https://www.linkedin.com/company/international-institute-of-medical-science-and-technology-council/" className="hover:underline flex items-center">
                      <FaLinkedin size={20} className="mr-2 text-[#0077B5]" /> LinkedIn
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@iimstcinstitute9801" className="hover:underline flex items-center">
                      <FaYoutube size={20} className="mr-2 text-[#FF0000]" /> YouTube
                    </a>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
